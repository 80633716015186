/* JugadorProfile.css */
.dorsal-container {
    background-color: #001f3f; /* Azul marino */
    padding: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
  }
  
  .dorsal-number {
    font-size: 40px;
    color: white;
    font-weight: bold;
  }
  
  .max-card {
    text-align: center;
    padding: 10px;
    background-color: #f8f9fa;
  }
  