.zona-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  position: relative; /* Contenedor relativo */
  padding: 0px;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columnas iguales */
  grid-template-rows: repeat(6, 1fr); /* 6 filas iguales */
  grid-template-areas: 
    "item-14 item-14 item-14 item-14 item-14 item-14"
    "item-17 item-6 item-6 item-7 item-7 item-15"
    "item-17 item-13 item-2 item-3 item-8 item-15"
    "item-17 item-12 item-5 item-4 item-9 item-15"
    "item-17 item-11 item-11 item-10 item-10 item-15"
    "item-16 item-16 item-16 item-16 item-16 item-16";
  grid-gap: 2px;
  width: 180px;
  height: 180px;
  position: relative; /* Necesario para la superposición del botón central */
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4CAF50;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.grid-item:hover {
  background-color: #45a049;
}

/* Botón central número 1 */
.central-button {
  position: absolute;
  top: 65%;
  left: 55%;
  transform: translate(-50%, -50%); /* Perfectamente centrado */
  background-color: #003366;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
  border-radius: 8px;
  width: 25px; /* Cuadrado perfecto */
  height: 25px; /* Cuadrado perfecto */
  z-index: 10; /* Asegura que esté encima de todos los demás */
}

.central-button:hover {
  background-color: #5144ff;
}

/* Grid de botones alrededor */
.item-14 { grid-area: item-14; background-color: #FF4500;}
.item-17 { grid-area: item-17; background-color: #FF4500;}
.item-15 { grid-area: item-15; background-color: #FF4500;}
.item-16 { grid-area: item-16; background-color: #FF4500;}
.item-6 { grid-area: item-6; background-color: #FFA500;}
.item-7 { grid-area: item-7; background-color: #FFA500;}
.item-11 { grid-area: item-11; background-color: #FFA500;}
.item-10 { grid-area: item-10; background-color: #FFA500;}
.item-13 { grid-area: item-13; background-color: #FFA500;}
.item-2 { grid-area: item-2; background-color: #4dff00c6;}
.item-3 { grid-area: item-3; background-color: #4dff00c6;}
.item-8 { grid-area: item-8; background-color: #FFA500;}
.item-12 { grid-area: item-12; background-color: #FFA500;}
.item-5 { grid-area: item-5; background-color: #4dff00c6;}
.item-4 { grid-area: item-4; background-color: #4dff00c6;}
.item-9 { grid-area: item-9; background-color: #FFA500;}

.resultado {
  margin-top: 20px;
  font-size: 20px;
}
