.velocidad-maxima {
    position: relative;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .velocidad-circulo {
    width: 50px;
    height: 50px;
    background-color: #ff6347;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px; /* Ajusta el tamaño del texto si es necesario */
    font-weight: bold;
    text-align: center;
    line-height: 1; /* Asegura que el texto no esté desalineado verticalmente */
    padding: 5px; /* Ajusta el padding si es necesario */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra */
  }
  